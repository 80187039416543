<script>
import LogoSvg from "@/components/Landing/LogoSvg.vue";
export default {
	name: "Footer",
	components: {
		LogoSvg
	},
	methods: {
		navToElement(id) {
			const element = document.getElementById(String(id))
			element.scrollIntoView({
				behavior: 'smooth',
			});
		}
	}
}
</script>

<template>
	<div id="footer">
		<div class="container">
			<LogoSvg style="filter: brightness(0) invert(1);" class="footer-logo" />
			<div class="footer-links">
				<a href="#" @click.prevent="navToElement('description')">Description</a>
				<a href="#" @click.prevent="navToElement('how-work')">How Romeo Works</a>
				<a href="#" @click.prevent="navToElement('benefits')">Benefits</a>
				<a href="" @click.prevent="navToElement('rev-slider')">Success Stories</a>
			</div>
		</div>
	</div>
</template>
