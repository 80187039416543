<script>
export default {
	name: "Profile"
}
</script>

<template>
	<svg width="34" height="38" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Group">
			<path id="Vector" d="M20.6307 22.439H13.3686C6.51536 22.439 0.957031 27.9966 0.957031 34.8505C0.957031 36.2213 2.06855 37.3335 3.43934 37.3335H30.5614C31.9322 37.3357 33.0422 36.2256 33.0422 34.8505C33.0422 27.9966 27.4846 22.439 20.6307 22.439Z" fill="#DA638E"/>
			<path id="Vector_2" opacity="0.4" d="M26.1664 9.83395C26.1664 14.8967 22.0627 19.0012 16.9992 19.0012C11.9358 19.0012 7.83203 14.8974 7.83203 9.83395C7.83203 4.7705 11.9365 0.666748 16.9992 0.666748C22.0627 0.666748 26.1664 4.77122 26.1664 9.83395Z" fill="#DA638E"/>
		</g>
	</svg>
</template>

<style scoped lang="scss">

</style>