<script>
import LogoSvg from "@/components/Landing/LogoSvg.vue";
export default {
	name: "Header",
	components:{
		LogoSvg
	},
	methods: {
		navToElement(id) {
			const element = document.getElementById(String(id))
			element.scrollIntoView({
				behavior: 'smooth',
			});
		}
	}
}
</script>

<template>
	<div id="header">
		<b-navbar toggleable="lg" type="dark">
			<b-navbar-brand href="/">
				<LogoSvg />
			</b-navbar-brand>

			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

			<b-collapse id="nav-collapse" is-nav>

				<!-- Right aligned nav items -->
				<b-navbar-nav class="ml-auto">
					<b-nav-item href="#" @click.prevent="navToElement('description')">Description</b-nav-item>
					<b-nav-item href="#" @click.prevent="navToElement('how-work')">How Romeo Works</b-nav-item>
					<b-nav-item href="#" @click.prevent="navToElement('benefits')">Benefits</b-nav-item>
					<b-nav-item href="" @click.prevent="navToElement('rev-slider')">Success Stories</b-nav-item>
				</b-navbar-nav>

				<b-navbar-nav class="ml-auto">
					<a href="/chat" class="header-btn">Begin</a>
				</b-navbar-nav>
			</b-collapse>
		</b-navbar>
	</div>
</template>

<style scoped lang="scss">

</style>