<script>
import Cookies from 'js-cookie';
import { setIPAddressCookie } from '../utils/setIpIntoCoockie';
import Header from "@/components/Landing/Header";
import Footer from "@/components/Landing/Footer";
import ReviewsSlider from "@/components/Landing/Reviews-slider";
import BenefitsSection from "@/components/Landing/BenefitsSection";
import PotentialSection from "@/components/Landing/PotentialSection";
import {pixelsMethods} from "@/state/helpers";

export default {
	name: "Index",
	components: {
		Header,
		Footer,
		ReviewsSlider,
		BenefitsSection,
		PotentialSection
	},
	methods: {
		...pixelsMethods,
		goToLogin() {
			return window.location.href = '/chat'
		}
	},
	mounted() {
		setIPAddressCookie();
		Cookies.set('net', this.$route.query.net);
		Cookies.set('aff', this.$route.query.aff);
		Cookies.set('cid', this.$route.query.cid);
		Cookies.set('sid', this.$route.query.sid);
		this.sendPixelEvent('pageview');
	}
}
</script>

<template>
	<div class="main-page">
		<div class="container">
			<Header/>
		</div>
		<div class="first-section">
			<div class="container">
				<div class="first-section-description">
					<h1>Expand your romantic horizons with ROMEO</h1>
					<p>Unlock a world of romantic possibilities and sexual choice. With Romeo, your personal
						AI dating assistant, navigate the dating world with confidence and charm</p>
				</div>
				<button class="promo-btn" @click="goToLogin">Explore Your Options Today</button>
			</div>
		</div>
		<div id="description" class="description-block">
			<div class="container">
				<div class="description-text">
					<div class="description-text-intro">
						<div class="intro-d">
							<p>Imagine a life where you're in control of your romantic and sexual destiny. A life where you have the ability to attract, engage, and captivate the women you desire.</p>
							<h3>That's the power of Romeo</h3>
							<p>Our advanced AI technology empowers you to become a master of communication, boosting your confidence and making you irresistible.<br>
								It's time to expand your romantic horizons and experience the thrill of choice.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<PotentialSection />
		<BenefitsSection />
		<div class="review-section container">
			<ReviewsSlider id="rev-slider"/>
		</div>
		<Footer/>
	</div>
</template>

<style scoped lang="scss">
.first-section {
	background: url('../../assets/images/main-img.webp') no-repeat;
	background-size: cover;
	height: 100%;
	padding-bottom: 50px;
}
.description-text{
	background: url('../../assets/images/flow-girl.webp') no-repeat;
	background-size: cover;
	@media (max-width: 1024px) {
		border-radius: 16px;
		border: 1px solid rgba(255, 255, 255, 0.10);
		background: linear-gradient(180deg, #0E436C 0%, #021837 100%);
		&-intro{
			background: url('../../assets/images/girl.webp') no-repeat top right !important;
			background-size: contain !important;
			max-width: 100% !important;
			.intro-d{
				max-width: 423px;
			}
		}
	}
	@media (max-width: 600px) {
		border-radius: 16px;
		border: 1px solid rgba(255, 255, 255, 0.10);
		background: linear-gradient(180deg, #0E436C 0%, #021837 100%);
		&-intro{
			background: none !important;
		}
	}
}
#footer{
	background: url('../../assets/images/footer-bg.webp') no-repeat;
	background-size: cover;
}
</style>