<script>
import MessageIcon from "@/components/Landing/icons/Message";
import ProfileIcon from "@/components/Landing/icons/Profile";
import CalendarIcon from "@/components/Landing/icons/Calendar";
import BookIcon from "@/components/Landing/icons/Book";
import LockedIcon from "@/components/Landing/icons/Locked";
export default {
	name: "BenefitsSection",
	components: {
		MessageIcon,
		ProfileIcon,
		CalendarIcon,
		BookIcon,
		LockedIcon
	}
}
</script>

<template>
	<div id="benefits" class="benefit-section">
		<div class="benefit-section-grid container">
			<div class="benefit-section-text">
				<h3>Harness the Wisdom of the World's Top Dating Experts with Romeo</h3>
				<button class="promo-btn" @click="scrollToTop">Unleash Your Potential with Romeo Today</button>
			</div>
			<div class="benefit-section-blocks">
				<div class="benefit-block-one">
					<div class="benefit-feature">
						<MessageIcon class="benefit-feature-icon" />
						<h5 class="benefit-feature-name">AI-Powered Messaging</h5>
						<p class="benefit-feature-step">Feature</p>
						<p class="benefit-feature-text">Romeo combines decades of wisdom from the world's top dating coaches and pickup artists with advanced AI technology to craft engaging, irresistible messages.</p>
						<p class="benefit-feature-step">Benefit</p>
						<p class="benefit-feature-text">Elevate your conversations from ordinary to extraordinary, captivating your potential partners and leaving a lasting impression.</p>
					</div>
					<div class="benefit-feature">
						<ProfileIcon class="benefit-feature-icon" />
						<h5 class="benefit-feature-name">Profile Optimization</h5>
						<p class="benefit-feature-step">Feature</p>
						<p class="benefit-feature-text">Romeo guides you in creating a profile that not just reflects you, but embodies the traits most attractive to women, based on proven strategies from dating experts.</p>
						<p class="benefit-feature-step">Benefit</p>
						<p class="benefit-feature-text">Stand out from the crowd and attract high-quality matches with a profile that is both authentic and enticing.</p>
					</div>
					<div class="benefit-feature">
						<CalendarIcon class="benefit-feature-icon" />
						<h5 class="benefit-feature-name">Automated Date<br> Scheduling</h5>
						<p class="benefit-feature-step">Feature</p>
						<p class="benefit-feature-text">Once a connection has been established, Romeo seamlessly schedules dates based on mutual interest and your availability.</p>
						<p class="benefit-feature-step">Benefit</p>
						<p class="benefit-feature-text">Experience hassle-free dating as Romeo manages your dating schedule, allowing you to focus on building connections and enjoying your dates.</p>
					</div>
				</div>
				<div class="benefit-block-two">
					<div class="benefit-feature">
						<BookIcon class="benefit-feature-icon" />
						<h5 class="benefit-feature-name">Continuous Learning<br> and Improvement</h5>
						<p class="benefit-feature-step">Feature</p>
						<p class="benefit-feature-text">Romeo is always learning, adapting and evolving based on your experiences, feedback and the latest insights from the world of dating and attraction.</p>
						<p class="benefit-feature-step">Benefit</p>
						<p class="benefit-feature-text">Stay ahead of the game with an AI dating assistant that continuously improves, keeping you on the cutting edge of dating strategies.</p>
					</div>
					<div class="benefit-feature">
						<LockedIcon class="benefit-feature-icon" />
						<h5 class="benefit-feature-name">Secure and Private</h5>
						<p class="benefit-feature-step">Feature</p>
						<p class="benefit-feature-text">Romeo prioritizes your privacy and security, ensuring your personal information and conversations are always protected.</p>
						<p class="benefit-feature-step">Benefit</p>
						<p class="benefit-feature-text">Date with peace of mind, knowing that your personal data and privacy are safeguarded.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.benefit-section{
	background: url('../../assets/images/benefit-bg.webp') no-repeat 100% 100%;
	background-size: cover;
}
</style>