<script>
export default {
	name: "PotentialSection"
}
</script>

<template>
	<div id="how-work" class="potential-section">
		<div class="container">
			<div class="text-center">
				<h3>Unlock Your Dating Potential with Romeo</h3>
				<p>It's as Simple as 1-2-3</p>
			</div>
			<div class="potential-steps">
				<div class="potential-step">
					<div class="potential-step-icon">
						<svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="41" cy="41" r="40.5" fill="url(#paint0_radial_185_40)" stroke="#AEEBFF"/>
							<path d="M36.2403 54.2255C35.8102 54.6557 35.2757 54.975 34.6892 55.1445L26.3384 57.6015C25.7896 57.7645 25.1959 57.6146 24.7912 57.1518C24.3864 56.8064 24.2351 56.2133 24.3966 55.6594L26.8533 47.3105C27.026 46.724 27.3421 46.1895 27.7742 45.7594L44.0808 29.4482L52.4948 37.8603L36.2403 54.2255Z" fill="#65C7F6"/>
							<path opacity="0.4" d="M47.972 25.5568C49.6013 23.9275 52.2474 23.9275 53.8767 25.5568L56.4446 28.1266C58.074 29.7553 58.074 32.3987 56.4446 34.0281L52.495 37.8603L44.0811 29.4483L47.972 25.5568Z" fill="#65C7F6"/>
							<defs>
								<radialGradient id="paint0_radial_185_40" cx="0" cy="0" r="1"
												gradientUnits="userSpaceOnUse"
												gradientTransform="translate(41 41) rotate(90) scale(41)">
									<stop stop-color="#051834"/>
									<stop offset="1" stop-color="#01061D"/>
								</radialGradient>
							</defs>
						</svg>
					</div>
					<p class="potential-step-step">Step 1</p>
					<h5 class="potential-step-name">Power Up<br> Your Profile</h5>
					<p class="potential-step-text">Sign up and let Romeo guide you in creating an enticing profile.
						Using proven strategies from top dating experts, Romeo helps you present yourself in a way that
						captures attention and sparks interest.</p>
				</div>
				<div class="potential-step">
					<div class="potential-step-icon">
						<svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="41" cy="41" r="40.5" fill="url(#paint0_radial_108_558)" stroke="#AEEBFF"/>
							<path
								d="M59.334 37.3584V48.3068C59.334 50.3264 57.6868 51.9219 55.6673 51.9219H53.834V55.0294C53.834 55.4877 53.3112 55.7525 52.946 55.4805L48.2051 51.9215L40.9491 52.0251C38.9783 52.0251 37.334 50.4266 37.334 48.3584V46.5262L42.834 46.5261C45.8636 46.5261 48.334 44.0557 48.334 41.0267V33.7433H55.6673C57.6897 33.7433 59.334 35.3876 59.334 37.3584Z"
								fill="#65C7F6"/>
							<path opacity="0.4" d="M42.8337 26.4099H26.3337C24.3141 26.4099 22.667 28.057 22.667 30.025V41.0766C22.667 43.0932 24.3141 44.7432 26.3337 44.7432L28.167 44.7438V47.8519C28.167 48.3101 28.6898 48.5749 29.055 48.3031L33.7959 44.7441L42.8337 44.7375C44.8532 44.7447 46.5003 43.0944 46.5003 41.0754V30.025C46.5003 28.057 44.8561 26.4099 42.8337 26.4099Z" fill="#65C7F6"/>
							<defs>
								<radialGradient id="paint0_radial_108_558" cx="0" cy="0" r="1"
												gradientUnits="userSpaceOnUse"
												gradientTransform="translate(41 41) rotate(90) scale(41)">
									<stop stop-color="#051834"/>
									<stop offset="1" stop-color="#01061D"/>
								</radialGradient>
							</defs>
						</svg>
					</div>
					<p class="potential-step-step">Step 2</p>
					<h5 class="potential-step-name">Watch Romeo<br> in Action</h5>
					<p class="potential-step-text">Leave the stress of starting conversations behind. Romeo crafts and
						sends engaging, personalized messages that captivate your matches. Enjoy the thrill of watching
						your conversations unfold effortlessly.</p>
				</div>
				<div class="potential-step">
					<div class="potential-step-icon">
						<svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="41" cy="41" r="40.5" fill="url(#paint0_radial_108_567)" stroke="#AEEBFF"/>
							<path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M58.6005 34.3999C58.6005 38.045 55.6455 40.9999 52.0005 40.9999C48.3554 40.9999 45.4005 38.045 45.4005 34.3999C45.4005 30.7548 48.3554 27.7999 52.0005 27.7999C55.6455 27.7999 58.6005 30.7548 58.6005 34.3999ZM55.0392 43.1999H55.3005C57.343 43.1988 59.3021 44.0097 60.7464 45.454C62.1907 46.8983 63.0016 48.8574 63.0005 50.8999C63.0005 52.7225 61.523 54.1999 59.7005 54.1999H47.5592C47.5622 54.1367 47.5705 54.0734 47.5789 54.0096C47.5896 53.9277 47.6005 53.8449 47.6005 53.7599V51.1199C47.6005 48.4662 46.5486 46.0668 44.8711 44.2587C46.0055 43.6056 47.298 43.1999 48.7005 43.1999H48.9617C49.9173 43.5299 50.928 43.7499 52.0005 43.7499C53.073 43.7499 54.0836 43.5299 55.0392 43.1999Z" fill="#65C7F6"/>
							<path fill-rule="evenodd" clip-rule="evenodd" d="M39.9 33.3C39.9 37.5526 36.4526 41 32.2 41C30.1575 41.0011 28.1983 40.1902 26.7541 38.7459C25.3098 37.3016 24.4989 35.3425 24.5 33.3C24.5 29.0474 27.9474 25.6 32.2 25.6C36.4526 25.6 39.9 29.0474 39.9 33.3ZM36.9094 43.2H37.48C41.8536 43.2011 45.3989 46.7464 45.4 51.12V53.1C45.4 54.9225 43.9225 56.4 42.1 56.4H22.3C20.4775 56.4 19 54.9225 19 53.1V51.12C19.0011 46.7464 22.5464 43.2011 26.92 43.2H27.4906C28.9275 43.8875 30.5087 44.3 32.2 44.3C33.8326 44.293 35.4426 43.9169 36.9094 43.2Z" fill="#65C7F6"/>
							<defs>
								<radialGradient id="paint0_radial_108_567" cx="0" cy="0" r="1"
												gradientUnits="userSpaceOnUse"
												gradientTransform="translate(41 41) rotate(90) scale(41)">
									<stop stop-color="#051834"/>
									<stop offset="1" stop-color="#01061D"/>
								</radialGradient>
							</defs>
						</svg>
					</div>
					<p class="potential-step-step">Step 3</p>
					<h5 class="potential-step-name">Meet Your<br> Matches</h5>
					<p class="potential-step-text">With the initial connection established, Romeo takes care of
						scheduling your dates. All you need to do is show up and enjoy the experience.</p>
				</div>
				<div class="potential-step">
					<div class="potential-step-icon">
						<svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="41" cy="41" r="40.5" fill="url(#paint0_radial_108_576)" stroke="#AEEBFF"/>
							<path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M42.692 27.5511C47.0155 23.0909 54.0194 22.3699 58.7305 26.3823C64.1256 30.9886 64.4101 39.2566 59.5709 44.2487L42.942 61.4182C42.4344 61.9496 41.7314 62.2503 40.9964 62.2503C40.2615 62.2503 39.5585 61.9496 39.0508 61.4182L22.4219 44.2487C17.5913 39.2566 17.8758 30.9886 23.2709 26.3823C27.9812 22.3699 34.9928 23.0909 39.3077 27.5511L40.9999 29.2956L42.692 27.5511ZM41.8953 54.6429L54.193 42.2807V42.2799C55.514 40.951 56.2128 39.1264 56.1174 37.2551C56.022 35.3838 55.1412 33.6397 53.692 32.4521C51.0227 30.2564 47.0464 30.7273 44.6075 33.1782L40.9663 36.8375L37.057 32.9101C35.7407 31.5838 33.9217 30.8809 32.0555 30.9774C30.1894 31.0738 28.4526 31.9605 27.2799 33.4154C25.0963 36.0993 25.5612 40.0936 27.9992 42.5446L40.0305 54.6429C40.2772 54.8913 40.6128 55.031 40.9629 55.031C41.313 55.031 41.6486 54.8913 41.8953 54.6429Z" fill="#65C7F6"/>
							<path d="M40.0301 54.6428L27.9988 42.5446C25.5608 40.0936 25.0959 36.0992 27.2804 33.4154C28.4529 31.9605 30.1895 31.0738 32.0556 30.9774C33.9216 30.8809 35.7404 31.5838 37.0567 32.9101L40.9659 36.8374L44.6071 33.1782C47.046 30.7282 51.0224 30.2564 53.6916 32.4521C55.1413 33.6392 56.0227 35.383 56.1187 37.2543C56.2148 39.1256 55.5166 40.9506 54.196 42.2799L41.8941 54.6428C41.6475 54.8912 41.3121 55.0309 40.9621 55.0309C40.6121 55.0309 40.2766 54.8912 40.0301 54.6428Z" fill="#65C7F6"/>
							<defs>
								<radialGradient id="paint0_radial_108_576" cx="0" cy="0" r="1"
												gradientUnits="userSpaceOnUse"
												gradientTransform="translate(41 41) rotate(90) scale(41)">
									<stop stop-color="#051834"/>
									<stop offset="1" stop-color="#01061D"/>
								</radialGradient>
							</defs>
						</svg>
					</div>
					<p class="potential-step-step">Step 4</p>
					<h5 class="potential-step-name">Experience Unforgettable Romance and Excitement</h5>
					<p class="potential-step-text">Now, the real fun begins. Dive into the amazing romance, enjoy the
						company of the woman of your dreams, or explore the excitement of dating multiple partners. With
						Romeo, the world of dating is your oyster.</p>
				</div>
			</div>
			<button class="promo-btn" @click="scrollToTop">Begin Your Romeo Adventure Today</button>
		</div>
	</div>
</template>