<script>
export default {
	name: "Calendar"
}
</script>

<template>
	<svg width="34" height="38" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Group">
			<path id="Vector" d="M12.4163 5.25008H21.583V2.95841C21.583 1.69298 22.6071 0.666748 23.8747 0.666748C25.1423 0.666748 26.1663 1.69298 26.1663 2.95841V5.25008H29.6038C31.5016 5.25008 33.0413 6.78908 33.0413 8.68758V14.4167H0.958008V8.68758C0.958008 6.78908 2.49701 5.25008 4.39551 5.25008H7.83301V2.95841C7.83301 1.69298 8.8571 0.666748 10.1247 0.666748C11.3923 0.666748 12.4163 1.69298 12.4163 2.95841V5.25008Z" fill="#DA638E"/>
			<path id="Vector_2" opacity="0.4" d="M0.958008 14.4167H33.0413V33.8959C33.0413 35.7937 31.5016 37.3334 29.6038 37.3334H4.39551C2.49701 37.3334 0.958008 35.7937 0.958008 33.8959V14.4167Z" fill="#DA638E"/>
		</g>
	</svg>
</template>

<style scoped lang="scss">

</style>