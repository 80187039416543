<script>
export default {
	name: "Message"
}
</script>

<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
		<g clip-path="url(#clip0_108_494)">
			<path d="M44.0003 17.6302V30.7677C44.0003 33.191 42.0239 35.1056 39.6005 35.1056H37.4006V38.8344C37.4006 39.3843 36.7733 39.702 36.3351 39.3757L30.6463 35.1051L21.9395 35.2293C19.5746 35.2293 17.6016 33.3113 17.6016 30.8295V28.631L24.2013 28.6309C27.8366 28.6309 30.8009 25.6665 30.8009 22.0319V13.2922H39.6005C42.0273 13.2922 44.0003 15.2653 44.0003 17.6302Z" fill="#DA638E"/>
			<path opacity="0.4" d="M24.2008 4.49268H4.40175C1.97842 4.49268 0.00195312 6.46915 0.00195312 8.8306V22.0919C0.00195312 24.5117 1.97842 26.4916 4.40175 26.4916L6.60164 26.4923V30.2218C6.60164 30.7717 7.22896 31.0894 7.66722 30.7632L13.356 26.4927L24.2008 26.4848C26.6241 26.4934 28.6006 24.5131 28.6006 22.0905V8.8306C28.6006 6.46915 26.6276 4.49268 24.2008 4.49268Z" fill="#DA638E"/>
		</g>
		<defs>
			<clipPath id="clip0_108_494">
				<rect width="44" height="44" fill="white"/>
			</clipPath>
		</defs>
	</svg>
</template>

<style scoped lang="scss">

</style>