<script>
export default {
	name: "Book"
}
</script>

<template>
	<svg width="40" height="44" viewBox="0 0 40 44" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Group">
			<path id="Path" opacity="0.4" d="M9 38.5C7.35 38.5 6.25 37.4 6.25 35.75C6.25 34.1 7.625 33 9 33H36.4424C36.2766 34.3956 36.2912 37.1577 36.488 38.5H9Z" fill="#DA638E"/>
			<path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M9 33H37.1875C38.3266 33 39.25 32.0766 39.25 30.9375V2.75C39.25 1.23122 38.0188 0 36.5 0H9C4.44365 0 0.75 3.69365 0.75 8.25V35.75C0.75 40.3064 4.44365 44 9 44H37.1875C38.3266 44 39.25 43.0766 39.25 41.9375V40.5625C39.25 39.4234 38.3266 38.5 37.1875 38.5H9C7.35 38.5 6.25 37.4 6.25 35.75C6.25 34.1 7.625 33 9 33ZM11.75 11.5156C11.75 11.2309 11.9809 11 12.2656 11H30.4844C30.7691 11 31 11.2309 31 11.5156V13.2344C31 13.5191 30.7691 13.75 30.4844 13.75H12.2656C11.9809 13.75 11.75 13.5191 11.75 13.2344V11.5156ZM12.2656 16.5C11.9809 16.5 11.75 16.7309 11.75 17.0156V18.7344C11.75 19.0191 11.9809 19.25 12.2656 19.25H30.4844C30.7691 19.25 31 19.0191 31 18.7344V17.0156C31 16.7309 30.7691 16.5 30.4844 16.5H12.2656Z" fill="#DA638E"/>
		</g>
	</svg>
</template>

<style scoped lang="scss">

</style>