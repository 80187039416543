import axios from 'axios';
import Cookies from 'js-cookie';

export async function setIPAddressCookie() {
	try {
		const response = await axios.get('https://api.ipify.org?format=json');
		const ipAddress = response.data.ip;
		Cookies.set('ipAddress', ipAddress, { expires: 1 }); // Set the IP address into a cookie
	} catch (error) {
		console.error('Error fetching IP address:', error);
	}
}
