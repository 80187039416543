<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
	name: "Reviews-slider",
	components: {
		VueSlickCarousel
	},
	data() {
		return{
			settings:{
				autoplay: false,
				dots: true,
				focusOnSelect: true,
				infinite: true,
				centerMode: true,
				centerPadding:  "0",
				speed: 500,
				slidesToShow: 3,
				slidesToScroll: 1,
				touchThreshold: 5,
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							arrows: false,
							slidesToShow: 1,
							slidesToScroll: 1
						}
					},
					{
						breakpoint: 480,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					}
				]
			},
			reviews: [
				{
					id: 1,
					authorName: "Sam, 35",
					title: "Romeo, the Wingman of the Century",
					text: "Romeo has been the perfect wingman. It's helped me navigate the dating scene with ease, landing me more dates than I could have ever managed on my own. It's truly revolutionized my dating life."
				},
				{
					id: 2,
					authorName: "Alex, 29",
					title: "A Dating Game Changer!",
					text: "Romeo has been a game-changer for my dating life. It's like having a world-class dating coach in my pocket. The conversations are engaging, the dates are fantastic, and I'm finally meeting the kind of women I've always dreamed about."
				},
				{
					id: 3,
					authorName: "Liam, 33",
					title: "From Shy to Confident",
					text: "I've always been shy and struggled with initiating conversations. But with Romeo, that's a thing of the past. Now I'm not just having conversations, I'm leading them and it's made all the difference. I'm dating more and enjoying it more."
				},
				{
					id: 4,
					authorName: "Daniel, 31",
					title: "Attracting Quality Matches",
					text: "Before Romeo, I was attracting the wrong kind of matches. Now, not only do I have a profile that truly represents me, but I'm also attracting high-quality matches that I'm genuinely excited about. I can't recommend Romeo enough!"
				},
			]
		}
	}
}
</script>

<template>
	<div class="review-carousel">
		<h3 class="text-center" style="margin-top: 10px;">Hear It From Our Successful Romeos</h3>
		<VueSlickCarousel v-bind="settings" class="review-carousel">
			<div class="slider-item" v-for="rev in reviews" :key="rev.id">
				<div class="slider-item-top">
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="24" viewBox="0 0 32 24" fill="none">
						<path d="M0 23.7955V17.25C0 15.3182 0.363636 13.2841 1.09091 11.1477C1.81818 9.01136 2.82955 6.96591 4.125 5.01136C5.42046 3.05681 6.94318 1.38636 8.69318 0L13.7386 3.61363C12.3523 5.63636 11.1705 7.75 10.1932 9.95454C9.21591 12.1591 8.72727 14.5568 8.72727 17.1477V23.7955H0ZM17.3864 23.7955V17.25C17.3864 15.3182 17.75 13.2841 18.4773 11.1477C19.2045 9.01136 20.2159 6.96591 21.5114 5.01136C22.8068 3.05681 24.3295 1.38636 26.0795 0L31.125 3.61363C29.7386 5.63636 28.5568 7.75 27.5795 9.95454C26.6023 12.1591 26.1136 14.5568 26.1136 17.1477V23.7955H17.3864Z" fill="white" fill-opacity="0.1"/>
					</svg>
					<div class="slider-item-avatar">
						<div class="img">
							<img v-if="rev.id===1" src="@/assets/images/revs/sam.webp" alt="">
							<img v-if="rev.id===2" src="@/assets/images/revs/alex.webp" alt="">
							<img v-if="rev.id===3" src="@/assets/images/revs/dale.webp" alt="">
							<img v-if="rev.id===4" src="@/assets/images/revs/daniel.webp" alt="">
						</div>
						<p class="auth-name">{{rev.authorName}}</p>
					</div>
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="24" viewBox="0 0 32 24" fill="none">
						<path d="M13.7045 0V6.54545C13.7045 8.47727 13.3409 10.5114 12.6136 12.6477C11.9091 14.7614 10.9091 16.7955 9.61364 18.75C8.31818 20.7045 6.79545 22.3864 5.04545 23.7955L0 20.1818C1.34091 18.2273 2.5 16.1477 3.47727 13.9432C4.47727 11.7386 4.97727 9.30682 4.97727 6.64773V0H13.7045ZM31.0568 0V6.54545C31.0568 8.47727 30.6932 10.5114 29.9659 12.6477C29.2614 14.7614 28.2614 16.7955 26.9659 18.75C25.6705 20.7045 24.1477 22.3864 22.3977 23.7955L17.3523 20.1818C18.6932 18.2273 19.8523 16.1477 20.8295 13.9432C21.8295 11.7386 22.3295 9.30682 22.3295 6.64773V0H31.0568Z" fill="white" fill-opacity="0.1"/>
					</svg>
				</div>
				<hr>
				<h5 class="slider-item-title">{{rev.title}}</h5>
				<p class="slider-item-text">{{ rev.text }}</p>
			</div>
			<template #prevArrow>
				<div class="custom-arrow">
					<svg xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82" fill="none">
						<path d="M49.5 58L33 41.5L49.5 25" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</div>
			</template>
			<template #nextArrow>
				<div class="custom-arrow">
					<svg xmlns="http://www.w3.org/2000/svg" width="82" height="82" viewBox="0 0 82 82" fill="none">
						<path d="M33 25L49.5 41.5L33 58" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
				</div>
			</template>
		</VueSlickCarousel>
		<button class="promo-btn" @click="scrollToTop">Join the Successful Romeos Today!</button>
	</div>
</template>

<style scoped lang="scss">

</style>