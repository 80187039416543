<script>
export default {
	name: "Locked"
}
</script>

<template>
	<svg width="34" height="38" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Group">
			<path id="Vector" d="M0.958008 19.0001C0.958008 16.4721 3.00977 14.4167 5.54134 14.4167H28.458C30.986 14.4167 33.0413 16.4721 33.0413 19.0001V32.7501C33.0413 35.2781 30.986 37.3334 28.458 37.3334H5.54134C3.00977 37.3334 0.958008 35.2781 0.958008 32.7501V19.0001Z" fill="#DA638E"/>
			<path id="Vector_2" opacity="0.4" d="M17 5.25008C13.8346 5.25008 11.2708 7.81532 11.2708 10.9792V14.4167H6.6875V10.9792C6.6875 5.28374 11.3066 0.666748 17 0.666748C22.6934 0.666748 27.3125 5.28374 27.3125 10.9792V14.4167H22.7292V10.9792C22.7292 7.81532 20.1654 5.25008 17 5.25008Z" fill="#DA638E"/>
		</g>
	</svg>
</template>

<style scoped lang="scss">

</style>